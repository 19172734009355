<template>
  <div>
    <optionscard :deliverParentNum="1"></optionscard>
    <!-- 中间的大图 -->
    <!-- 轮播图 -->
    <!-- <carousel :deliverParentPic="slideshowList" class="carouselDiv"></carousel> -->
    <div class="carouselDiv">
      <!--动态将图片轮播图的容器高度设置成与图片一致-->
      <el-carousel class="elCarousel">
        <!--遍历图片地址,动态生成轮播图-->
        <el-carousel-item v-for="(item, index) in slideshowList" :key="index">
          <img :src="item" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 中间部分 -->
    <div class="middleDiv">
      <!-- 宽1200px的div -->
      <div class="width1200">
        <!-- 第一块 （3个图片） -->
        <div class="middle-first">
          <div class="picture2" @click="venueActivity()">
            <!-- 图片 -->
            <img
              src="@/assets/home/zhuye/picture12.png"
              width="100%"
              height="100%"
            />
            <!-- 图片底部半透明阴影 -->
            <div class="shadow"></div>
            <!-- 阴影的字 -->
            <div class="shadow-word">
              场馆活动
              <!-- 小图标 -->
              <i class="iconfont icon-xiangyou" id="cghdIcon"></i>
            </div>
          </div>
          <div class="picture2" @click="earthquakeKnowledge()">
            <!-- 图片 -->
            <img
              src="@/assets/home/zhuye/picture11.png"
              width="100%"
              height="100%"
            />
            <!-- 图片底部半透明阴影 -->
            <div class="shadow"></div>
            <!-- 阴影的字 -->
            <div class="shadow-word">
              地震知识
              <!-- 小图标 -->
              <i class="iconfont icon-xiangyou" id="dzzsIcon"></i>
            </div>
          </div>
          <div class="picture6" @click="goVenue()">
            <!-- 图片 -->
            <img
              src="@/assets/home/zhuye/picture10.png"
              width="100%"
              height="100%"
            />
            <!-- 图片底部半透明阴影 -->
            <div class="shadow1"></div>
            <!-- 阴影的字 -->
            <div class="shadow-word1">
              走进场馆
              <!-- 小图标 -->
              <i class="iconfont icon-xiangyou" id="zjcgIcon"></i>
            </div>
          </div>
        </div>
        <!-- 第二块 （文章） -->
        <div class="middle-second">
          <!-- 文章 -->
          <!-- 第一份(场馆活动) -->
          <div class="one-word">
            <div
              class="one-word-body"
              v-for="item in activityList"
              :key="item.id"
              @click="getactivityArticle(item.id)"
            >
              <!-- 文章标题 -->
              <div class="one-wordtop1">
                {{ item.title }}
              </div>
              <!-- 文章内容 -->
              <div class="one-wordmiddle" v-html="item.content"></div>
              <!-- 活动时间 -->
              <div class="one-wordbottom">
                活动时间：{{ item.start_time }} - {{ item.end_time }}
              </div>
            </div>
          </div>
          <!-- 第二份(地震知识) -->
          <div class="one-word">
            <div
              class="one-word-body"
              v-for="item in knowledgeList"
              :key="item.id"
              @click="getknowledgeArticle(item.id)"
            >
              <!-- 文章标题 -->
              <div class="one-wordtop1">{{ item.title }}</div>
              <!-- 文章内容 -->
              <div class="one-wordmiddle1" v-html="item.content"></div>
            </div>
          </div>
          <!-- 导航 -->
          <div class="navigation">
            <div class="navigation1" @click="virtualOmnirange(1)">
              <!-- 虚拟导航 -->
              <div class="navigation2">
                虚拟导航
                <i class="iconfont icon-xiangyou" id="xndhIcon"></i>
              </div>
            </div>
            <div class="navigation12" @click="offlineAppointment()">
              <!-- 线下预购 -->
              <div class="navigation2">
                线下预约
                <i class="iconfont icon-xiangyou" id="offlineBooking"></i>
              </div>
            </div>
            <div class="navigation13">
              <div class="navigation3">场馆信息</div>
            </div>
          </div>
        </div>
        <!-- 第三块（避震攻略） -->
        <div class="bzgldiv">
          <!-- 避震攻略头部加文章 -->
          <div>
            <!-- 避震攻略头部 -->
            <div class="bzheadline">
              <div class="bluebulk"></div>
              <div class="blueword">避震攻略</div>
              <div class="bzheadline-more" @click="bzglmore">更多</div>
            </div>
            <!-- 避震文章 -->
            <div class="essay">
              <!-- 第一份 -->
              <div
                class="one-essay"
                v-for="item in bzglList"
                :key="item.id"
                @click="gobzglArticle(item.id)"
              >
                <!-- 图片 -->
                <div class="picture3">
                  <!-- img :src="item.image" -->
                  <img :src="item.image" width="100%" height="100%" />
                </div>
                <!-- 图片旁边的文字 -->
                <div class="one-essay-word">
                  <div class="essay-headline">{{ item.title }}</div>
                  <div class="essay-word" v-html="item.content"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- 联系信息 -->
          <div class="contactDiv">
            <div class="contactDivInner">
              联系电话：<br />
              +86-754-88888888(总机)
              <br /><br />
              地址：<br />
              广东省汕头市地震局
              <br /><br />
              <br />
              <span @click="getServe()" class="getserve">更多参观服务信息</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import optionscard from '@/components/optionscard.vue'
// import carousel from '../../components/Carousel.vue'
export default {
  components: {
    // 头部选项卡（首页 场馆展品 场馆活动 地震知识 参观服务
    optionscard
    // 轮播图
    // carousel
  },
  data () {
    return {
      // 场馆活动参数
      activityarticle: {
        paginate: 2
      },
      // 地震知识参数
      knowledgearticle: {
        pid: 2,
        paginate: 2
      },
      // 避震攻略参数
      bzglarticle: {
        pid: 1,
        paginate: 2
      },
      // 场馆活动 文章标题、内容、时间数据
      activityList: [],
      // 地震知识 文章标题、内容数据
      knowledgeList: [],
      // 避震攻略 文章标题、内容、时间数据
      bzglList: [],
      slideshowList: [],
      // 存放轮播图数据
      carouselList: []
    }
  },
  created () {
    this.getActivityList()
    this.getBzglList()
    this.getKnowledgeList()
    this.getCarousel()
  },

  methods: {
    // 场馆活动 文章标题、内容、时间渲染
    async getActivityList () {
      const { res } = await this.$api.homepage.getActivity(
        this.activityarticle
      )
      console.log('this.activityarticle', this.activityarticle)
      console.log('场馆活动res', res)
      for (const i of res.data) {
        // 去除时间后面的时分秒
        if (i.start_time.length > 10) {
          i.start_time = i.start_time.slice(0, 10)
        }
        if (i.end_time.length > 10) {
          i.end_time = i.end_time.slice(0, 10)
        }
        //  删去内容里的html标签
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
      }
      console.log('res.data', res.data)
      this.activityList = res.data
    },
    // 地震知识 文章标题、内容渲染
    async getKnowledgeList () {
      const { res } = await this.$api.homepage.getKnowledge(
        this.knowledgearticle
      )
      console.log('地震知识res', res)
      for (const i of res.data) {
        //  删去内容里的html标签
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
      }
      console.log('res.data', res.data)
      this.knowledgeList = res.data
    },
    // 避震攻略 文章标题、内容
    async getBzglList () {
      const { res } = await this.$api.homepage.getBzgl(this.bzglarticle)
      console.log('this.bzglarticle', this.bzglarticle)
      console.log('避震攻略res', res)
      for (const i of res.data) {
        //  删去内容里的img标签
        const reg = /<\/?.+?\/?>/g
        i.content = i.content.replace(reg, '')
        i.content = i.content.replace(/<\/?p>/g, '')
        i.content = i.content.replace(/&nbsp;/gi, '')
      }
      console.log('res.data', res.data)
      this.bzglList = res.data
    },
    // 获取轮播图
    async getCarousel () {
      const { res } = await this.$api.homepage.getCarousel()
      console.log('轮播图res', res)
      res.index.forEach((e) => {
        this.slideshowList.push(e.pic)
      })
      console.log('slideshowList', this.slideshowList)
    },
    // 更多（避震攻略按钮）
    bzglmore () {
      this.$router.push('/homepage/bzglfirst')
    },
    // 更多参观服务信息
    getServe () {
      this.$router.push('/sixth/cgfxfirst')
    },
    // 跳转到场馆活动
    venueActivity () {
      this.$router.push('/forth/cghdfirst')
    },
    // 跳转到地震知识
    earthquakeKnowledge () {
      this.$router.push('/fifth/dzzsfirst')
    },
    // 走进场馆（跳转到场馆展品）
    goVenue () {
      this.$router.push('/third/cgzpfirst')
    },
    // 虚拟导航（跳转到参观服务的地图）
    virtualOmnirange (t) {
      // console.log('t', t)
      this.$router.push({ path: '/sixth/cgfxfirst', query: { t } })
    },
    // 线下预约（跳转到场馆活动的预约单）
    offlineAppointment () {
      this.$router.push({
        path: '/forth/cghdfirst'
      })
    },
    // 场馆活动文章详情跳转
    getactivityArticle (id) {
      this.$router.push({ path: '/forth/cghdfirst/cghdsecond', query: { id } })
    },
    // 地震知识文章详情跳转
    getknowledgeArticle (id) {
      this.$router.push({ path: '/fifth/dzzsfirst/dzzssecond', query: { id } })
    },
    // 避震攻略文章详情跳转
    gobzglArticle (id) {
      this.$router.push({ path: 'bzglsecond/bzglthird', query: { id } })
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/.el-carousel__container {
  height: 650px;
}

/deep/.el-carousel__arrow {
  width: 70px;
  height: 70px;
  font-size: 35px;
}

.carouselDiv {
  background-color: #7ebbf8;
}

.middleDiv {
  margin-top: 50px;
}

.bigimg {
  width: 100%;
}

.width1200 {
  width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
}

.middle-first {
  display: flex;
  justify-content: space-between;
}

.picture2 {
  position: relative;
  display: inline-block;
  margin-right: 10px;
  width: 469px;
  height: 220px;
  cursor: pointer;
}

.picture6 {
  position: relative;
  display: inline-block;
  width: 235px;
  height: 220px;
  cursor: pointer;
}

.one-word {
  display: inline-block;
  width: 469px;
  box-shadow: 0 0 10px 0 #dfdddd;
}

.one-word-body {
  cursor: pointer;
  height: 136px;
  border: 1px solid #eaeaea;
  border-top: 0px;
}

.one-word-body:hover .one-wordtop1,
.one-wordtopblue2 {
  color: #2e88e4;
  cursor: pointer;
}

.one-word-body:hover .one-wordtopblue2 {
  color: #2e88e4;
  cursor: pointer;
}

.one-wordtop1 {
  width: 351px;
  height: 22px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  margin: 0px 0 0 17px;
  padding-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.one-wordtop2 {
  width: 351px;
  height: 19px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #2e88e4;
  margin: 30px 0 0 17px;
}

.one-wordmiddle {
  width: 416px;
  height: 36px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #777777;
  margin-left: 17px;
  margin-top: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.one-wordmiddle1 {
  width: 416px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #777777;
  margin-left: 17px;
  margin-top: 17px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.one-wordbottom {
  height: 30px;
  line-height: 30px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  margin: 0 0 0 20px;
  background-color: #f7f7f7;
  display: inline-block;
  padding: 0 10px;
}

.one-wordtopblue2 {
  width: 351px;
  height: 19px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #333333;
  margin: 48px 0 0 17px;
}

.one-wordtopblue2:hover {
  color: #2e88e4;
  cursor: pointer;
}

.middle-second {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.shadow {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 50px;
  background-color: #000;
  opacity: 0.5;
}

.shadow1 {
  position: absolute;
  width: 100%;
  height: 48px;
  bottom: 0;
  background-color: #000;
  opacity: 0.5;
}

.shadow-word {
  position: absolute;
  bottom: 13px;
  left: 20px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

#cghdIcon,
#dzzsIcon,
#zjcgIcon {
  float: right;
  font-weight: 400;
}

.shadow-word1 {
  position: absolute;
  bottom: 13px;
  left: 20px;
  width: 100%;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}

.icon-xiangyou {
  font-size: 25px;
  margin-right: 44px;
}

.navigation {
  width: 235px;
}

.navigation1 {
  width: 100%;
  height: 70px;
  margin-top: 13px;
  background-color: #2e88e4;
  cursor: pointer;
}

.navigation12 {
  width: 100%;
  height: 70px;
  margin-top: 13px;
  background-color: #ffa92e;
  cursor: pointer;
}

.navigation13 {
  width: 100%;
  height: 70px;
  margin-top: 13px;
  background-color: #494949;
}

.navigation2 {
  display: flex;
  justify-content: space-between;
  margin-left: 55px;
  line-height: 70px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

#xndhIcon,
#offlineBooking {
  margin-right: 70px;
  font-weight: 400;
}

.navigation3 {
  margin-left: 25px;
  line-height: 70px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
}

.getserve {
  cursor: pointer;
  border-bottom: 1px solid #494949;
  padding-bottom: 5px;
}

.getserve:hover {
  color: #2e88e4;
  border-bottom: 1px solid #2e88e4;
}

.essay {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}

.bzgldiv {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.bzheadline {
  display: flex;
  margin-top: 30px;
}

.bluebulk {
  width: 6px;
  height: 25px;
  background-color: #2e88e4;
  display: inline-block;
  margin-right: 8px;
}

.blueword {
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
}

.bzheadline-more {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
}

.bzheadline-more:hover {
  color: #2e88e4;
}

.one-essay {
  display: flex;
  margin-top: 25px;
  width: 470px;
  height: 120px;
  cursor: pointer;
  border: 1px solid #f7f7f7;
  margin-left: 10px;
  box-shadow: 0 0 25px 0 #dfdddd;
}

.one-essay:first-child {
  margin-left: 0px;
}

.essay-headline {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  margin-top: 25px;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.one-essay:hover .essay-headline {
  color: #2e88e4;
}

.essay-word {
  width: 272px;
  height: 42px;
  margin-top: 10px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #777777;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.picture3 {
  display: inline-block;
  width: 96px;
  height: 96px;
  margin-left: 10px;
  margin-top: 12px;
}

.one-essay-word {
  margin-left: 18px;
}

.contactDiv {
  width: 232px;
  height: 240px;
  position: absolute;
  top: -38px;
  right: 0px;
  border: 1px solid #f7f7f7;
  border-top: 0px;
  box-shadow: 0 0 10px 0 #dfdddd;
}

.contactDivInner {
  width: 200px;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 9px;
  font-size: 16px;
}
</style>
